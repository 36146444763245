import SchedulerFactory from "./scheduler_factory";
import allExtensions from "./ext/extensions_all";
var factory = new SchedulerFactory(allExtensions);
var scheduler = factory.getSchedulerInstance();
var Scheduler = {
  plugin: scheduler.bind(factory.plugin, factory)
};
window.scheduler = scheduler;
window.Scheduler = Scheduler;
export default scheduler;
export { scheduler, Scheduler };